import React from 'react';
import { PlatformTabEmptyState, PlatformTab } from 'docComponents';

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <PlatformTabEmptyState platform="reactnative" pageType="guidelines" />
    </PlatformTab>
  );
};

export default ReactNativeTab;
