import React from 'react';
import { PlatformTabEmptyState, PlatformTab } from 'docComponents';

const AndroidTab = () => {
  return (
    <PlatformTab>
      <PlatformTabEmptyState platform="android" pageType="guidelines" />
    </PlatformTab>
  );
};

export default AndroidTab;
